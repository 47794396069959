var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ReactShakaPlayer } from '@mkhuda/react-shaka-player';
export const VideoPlayer = ({ videoUrl, captionsUrl }) => {
    const [mainPlayer, setMainPlayer] = useState(null);
    useEffect(() => {
        if (!mainPlayer) {
            return;
        }
        const { player, videoElement } = mainPlayer;
        function load() {
            return __awaiter(this, void 0, void 0, function* () {
                yield player.load(videoUrl);
                if (captionsUrl) {
                    yield player.addTextTrackAsync(captionsUrl, 'en', 'captions');
                }
            });
        }
        if (player && videoElement) {
            void load();
        }
    }, [mainPlayer]);
    return _jsx("div", Object.assign({ className: "video-player" }, { children: _jsx(ReactShakaPlayer, { className: "amsterdam-acid-red", config: {
                streaming: {
                    bufferingGoal: 30,
                    rebufferingGoal: 15,
                    bufferBehind: 30,
                    retryParameters: {
                        maxAttempts: 3,
                        baseDelay: 500,
                        backoffFactor: 2,
                    },
                    failureCallback: (error) => {
                        // eslint-disable-next-line no-console
                        console.warn('Player error:', error);
                    }
                }
            }, uiConfig: {
                overflowMenuButtons: ['quality', 'captions', 'picture_in_picture', 'playback_rate'],
                seekBarColors: {
                    base: 'rgba(167, 25, 28, 0.5)',
                    buffered: 'rgba(167, 25, 28, 0.7)',
                    played: 'rgba(211, 32, 36, 0.8)',
                },
                volumeBarColors: {
                    base: 'rgba(167, 25, 28, 0.8)',
                    level: 'rgb(211, 32, 36)',
                }
            }, onLoad: player => setMainPlayer(player) }, void 0) }), void 0);
};
