import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { NewTabLink } from '../components/Links';
// Binding by convention: Keys for both Titles and Blurbs must be the base name of the video file.
export const Titles = {
    GCode: 'G-Code',
    LockstepConfiguration: 'Lockstep',
    OscilloscopeBasics: 'Oscilloscope Basics',
};
export const Blurbs = {
    GCode: _jsxs(Text, { children: [_jsx("p", { children: "The G-Code application in Zaber Launcher allows you to treat your Zaber device as a CNC machine or a 3D Printer. You can input individual G-Code commands or run entire programs." }, void 0), _jsxs("p", { children: ["The video demonstrates how to use the G-Code application in Zaber Launcher. If you want to learn more about the G-Code API or the supported G-Codes, please refer to the  ", _jsx(NewTabLink, Object.assign({ to: "https://software.zaber.com/motion-library/docs/guides/gcode" }, { children: "Zaber Motion Library documentation" }), void 0), "."] }, void 0)] }, void 0),
    LockstepConfiguration: _jsxs(Text, { children: [_jsxs("p", { children: ["Zaber Multi-Axis Controllers such as the  ", _jsx(NewTabLink, Object.assign({ to: "https://www.zaber.com/products/controllers-joysticks/X-MCC#slide-x-mcc4" }, { children: "X-MCC series" }), void 0), "  have a  ", _jsx(NewTabLink, Object.assign({ to: "https://www.zaber.com/articles/driving-parallel-axes-with-lockstep-movement" }, { children: "lockstep mode" }), void 0), "  that causes two or more motion axes to move in sync. This tutorial demonstrates how to use Zaber Launcher to configure lockstep movement."] }, void 0), _jsxs("p", { children: ["Once lockstep mode is established, different commands must be used to control movement. Zaber Launcher handles this automatically, but if you are using the Zaber Motion Library you should use the  ", _jsx(NewTabLink, Object.assign({ to: "https://software.zaber.com/motion-library/docs/guides/lockstep" }, { children: "Lockstep class" }), void 0), "  or in other software use the Zaber ASCII Protocol  ", _jsx(NewTabLink, Object.assign({ to: "https://www.zaber.com/protocol-manual#topic_command_lockstep" }, { children: "lockstep commands" }), void 0), "."] }, void 0)] }, void 0),
    OscilloscopeBasics: _jsxs(Text, { children: [_jsx("p", { children: "The Oscilloscope application in Zaber Launcher charts changes in device settings and I/O pins over time. It can be useful in measuring device behavior under load or tuning servo devices for performance." }, void 0), _jsxs("p", { children: ["The Oscillscope can read data under software control or make use of Zaber Firmware's built-in  ", _jsx(NewTabLink, Object.assign({ to: "https://www.zaber.com/protocol-manual#topic_command_scope" }, { children: "Oscilloscope feature" }), void 0), "to record data with accurate, higher-resolution timing."] }, void 0), _jsx("p", { children: "It's easy to run the same experiment over and over again, and the recorded data can be exported to spreadsheets." }, void 0)] }, void 0),
};
