import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { environment } from '../environment';
import { VideoPlayer } from './VideoPlayer';
const BASE_URL = environment.build === 'production'
    ? 'https://software.zaber.com/video/tutorials/'
    : 'https://cloud-staging.zaber.io/video/tutorials/';
const VIDEO_EXT = 'mpd';
const CAPTIONS_EXT = 'srt';
const videoUrl = (subject) => `${BASE_URL}${subject}.${VIDEO_EXT}`;
const captionsUrl = (subject) => `${BASE_URL}${subject}.${CAPTIONS_EXT}`;
export const TutorialSection = ({ title, videoSubject, children }) => _jsxs("div", Object.assign({ className: "overview-section" }, { children: [_jsxs("div", Object.assign({ className: "overview-description" }, { children: [_jsx(Text, Object.assign({ t: Text.Type.H4, className: "section-title" }, { children: title }), void 0), children] }), void 0), _jsx("div", Object.assign({ className: "overview-image" }, { children: _jsx(VideoPlayer, { videoUrl: videoUrl(videoSubject), captionsUrl: captionsUrl(videoSubject) }, void 0) }), void 0)] }), void 0);
